import React from 'react'

import { Link, navigate } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import './404.less'

class NotFoundPage extends React.Component {

	goTo = (e, link, white = false) => {
		e.preventDefault();
		if (white) {
			document.body.classList.add('cover', 'white');
		}
		else {
			document.body.classList.add('cover');
		}
		setTimeout(() => {
			navigate(link)
		}, 700);
	}

	componentDidMount = () => {
		document.body.classList.add('remove');
		setTimeout(() => {
			document.body.classList.remove('cover', 'remove', 'white');
		}, 800);
		setTimeout(() => { this.setState({ content: 'visible' }) }, 300);
		document.body.classList.remove('scroll-stop');
	}

	render() {

		return <Layout>
			<SEO title="Error 404 - Page Not found" />
			<div className="p-error">
				<div className="uk-container">
					<h1>Sorry, we can't find the page<br />you're looking for.</h1>
					<Link className="default-button" to="/" onClick={(e) => this.goTo(e, '/')}>Start here</Link>
				</div>
			</div>
		</Layout>
	}
}

export default NotFoundPage
